import React from 'react'
import './Nav.css'
import bmLogo from './images/bmfuture logo.jpg'


const Logo= ()=> {

    return(
        <div id='logo-container'><img id='logo' src={bmLogo} alt=''/></div>
    )
}

export default Logo;