import React from 'react';
import './App.css';
import Main from './Components/Main.js'

function App() {

  return (
    <div className="App">

      <Main>


      </Main>

    </div>
  );
}

export default App;
